<template>
    <div>
        <v-toolbar>
            <b-row>
                <b-col cols="3">
                    <router-link
                        :to="{name: 'AdmissionActCreate'}"
                        class="btn btn-success"
                    >
                        <b-icon-plus scale="1.2"/>
                    </router-link>
                </b-col>
                <b-col cols="9" class="text-right">
                    <button @click="downloadCsv()" v-if="!!filters.dateFrom && !!filters.dateTo"
                            class="btn btn-success mr-1">
                        <i v-if="!csvLoading" class="fas fa-download"></i>
                        <i v-else class="fas fa-spinner fa-spin"></i>
                        CSV
                    </button>
                    <b-button variant="primary" @click="applyFilters" class="mr-1">
                        <b-icon-arrow-repeat font-scale="1.2"/>
                    </b-button>
                    <b-button v-b-toggle.filters variant="light">
                        <b-icon-filter/>
                        Фильтры
                    </b-button>
                    <small v-if="filtersExists">
                        <b-link @click.prevent="resetFilters">(Сбросить)</b-link>
                    </small>
                </b-col>
            </b-row>
            <b-collapse id="filters">
                <b-card-body>
                    <b-row>
                        <b-col md="2">
                            <b-form-group description="Дата (от)">
                                <b-form-datepicker
                                    :date-format-options="{ year: 'numeric', month: '2-digit', day: '2-digit' }"
                                    :start-weekday="1"
                                    locale="ru"
                                    placeholder=""
                                    v-model="filters.dateFrom"
                                />
                            </b-form-group>
                        </b-col>
                        <b-col md="2">
                            <b-form-group description="Дата (до)">
                                <b-form-datepicker
                                    :date-format-options="{ year: 'numeric', month: '2-digit', day: '2-digit' }"
                                    :start-weekday="1"
                                    locale="ru"
                                    placeholder=""
                                    v-model="filters.dateTo"
                                />
                            </b-form-group>
                        </b-col>
                        <b-col md="2">
                            <b-form-group description="Статус">
                                <act-status-picker v-model="filters.status"></act-status-picker>
                            </b-form-group>
                        </b-col>
                        <b-col md="3">
                            <b-form-group description="Склад">
                                <storage-picker
                                    v-model="filters.storage"
                                    material
                                    :allowed-roles="[ROLE_STOCKMAN, ROLE_QUALITY_CONTROL]"
                                />
                            </b-form-group>
                        </b-col>
                        <b-col md="3">
                            <b-form-group description="Поиск по номенклатуре">
                                <b-form-input v-model='filters.search_nomenclature' debounce="500" />
                            </b-form-group>
                        </b-col>
                    </b-row>
                </b-card-body>
            </b-collapse>
        </v-toolbar>
        <b-table
            responsive
            :items="items"
            :fields="fields"
            head-variant="dark"

            :sort-by.sync="orderBy.by"
            :sort-desc.sync="orderBy.desc"
            no-local-sorting
            no-sort-reset
            @row-dblclicked="toForm"
        >
            <template #cell(actions)="data">
                <b-button-group>
                    <b-button
                        @click="toForm(data.item)"
                        size="sm"
                        variant="warning"
                    >
                        <b-icon-pencil-square/>
                    </b-button>
                    <b-button variant="light" size="sm" v-if="data.item.canceled" @click="changeActStatus(data.item)">
                        <b-icon-arrow-return-left/>
                    </b-button>
                    <b-button variant="danger" size="sm" v-else @click="showCancelModal(data.item)">
                        <b-icon-trash-fill/>
                    </b-button>
                </b-button-group>
            </template>
        </b-table>

        <b-row>
            <b-col md="6">
                <span>
                    Показано с {{ meta.current_page * meta.per_page - meta.per_page + 1 }}
                    по {{ meta.current_page * meta.per_page - meta.per_page + items.length }}
                    из {{ meta.total_rows }} записей
                </span>
            </b-col>
            <b-col md="6">
                <b-pagination
                    v-model="meta.current_page"
                    :total-rows="meta.total_rows"
                    :per-page="meta.per_page"
                    @input="applyFilters"
                    class="justify-content-end"
                />
            </b-col>
        </b-row>

        <b-modal centered :id="cancelActModal.id" title="Отмена акта снабжения">
            <div class="row">
                <div class="col-12">
                    <b-form-group description="Комментарий">
                        <textarea class="form-control" v-model="cancelActModal.act.comment_of_cancel"></textarea>
                    </b-form-group>
                </div>
            </div>
            <template #modal-footer="{ ok, cancel }">
                <b-button size="sm" variant="success" @click="cancel()">
                    Закрыть
                </b-button>
                <b-button size="sm" variant="danger" @click="changeActStatus(cancelActModal.act);">
                    Отменить
                </b-button>
            </template>

        </b-modal>
    </div>
</template>

<script>
import debounce                              from 'lodash/debounce';
import {ADMISSION_ACTS_RESOURCE_ENDPOINT}    from '@utils/endpoints';
import lsfMixin                              from '@utils/localStorageFilterMixin';
import moment                                from 'moment';
import DatePicker                            from 'vue2-datepicker';
import StoragePicker                         from '@components/_common/StoragePicker';
import ActStatusPicker                       from '@components/_common/ActStatusPicker';
import {forEach, map, join}                  from 'lodash';
import CSV                                   from '@utils/csv';
import {ROLE_STOCKMAN, ROLE_QUALITY_CONTROL} from '@utils/Roles';
import VToolbar                              from '@components/ui/toolbar/VToolbar';

export default {
    name: 'Index',
    components: {StoragePicker, ActStatusPicker, DatePicker, VToolbar},
    mixins: [lsfMixin],
    data() {
        return {
            filters: {
                dateTo: null,
                storage: null,
                dateFrom: null,
                search_nomenclature: null,
            },
            ROLE_STOCKMAN,
            ROLE_QUALITY_CONTROL,
            csvLoading: false,
            orderBy: {by: 'date', desc: true},
            fields: [
                {
                    key: 'id',
                    label: '№',
                },
                {
                    key: 'date',
                    label: 'Дата',
                    formatter(value) {
                        return moment(value).format('D.MM.YY HH:mm');
                    },
                    sortable: true
                },
                {
                    key: 'status',
                    label: 'Статус',
                    formatter(status, key, item) {
                        return item.canceled === true
                            ? 'Отменен'
                            : status.name[0].toUpperCase() + status.name.slice(1);
                    },
                },
                {
                    key: 'nomenclaturePreview',
                    label: 'Номенклатура',
                    tdClass: 'nomenclature_preview'
                },
                {
                    key: 'storage.name',
                    label: 'Склад',
                },
                {
                    key: 'contractor.name',
                    label: 'Поставщик',
                },

                {
                    key: 'actions',
                    label: 'Действия'
                }
            ],
            items: [],
            meta: {
                current_page: 1,
            },
            cancelActModal: {
                id: 'cancel-act-modal',
                act: {
                    comment_of_cancel: null,
                },
            }
        }
    },
    computed: {
        preparedConditions() {
            let conditions = {
                orders: {},
            };

            forEach(this.getFilters, function (filter, key) {
                if (filter) {
                    switch (key) {
                        case 'dateFrom':
                            conditions['filters[dateFrom]'] = filter ? moment(filter).format('YYYY-MM-DD') : null;
                            break;
                        case 'dateTo':
                            conditions['filters[dateTo]'] = filter ? moment(filter).format('YYYY-MM-DD') : null;
                            break;
                        case 'storage':
                            conditions['filters[storageId]'] = filter.id;
                            break;
                        case 'page':
                            conditions['page'] = filter;
                            break;
                        case 'status':
                            conditions['filters[act_status_id]'] = filter.id;
                            break;
                        default:
                            conditions['filters[' + key + ']'] = filter;
                            break;
                    }
                }
            });

            conditions.page = this.meta.current_page;

            conditions.orders[this.orderBy.by] = this.orderBy.desc ? 'desc' : 'asc';

            conditions['with'] = [
                'storage',
                'contractor',
                'items.nomenclature',
            ];

            return conditions;
        },
    },
    watch: {
        orderBy: {
            deep: true,
            handler() {
                this.applyFilters();
            }
        },
        filters: {
            deep: true,
            handler(value) {
                this.watchFilters(value);
            },
        },
    },
    methods: {
        prepareItems(actData) {
            return map(actData, act => {
                act.nomenclaturePreview = join(map(act.items, item => item.nomenclature.name), ', ');
                return act;
            });
        },
        applyFilters() {
            let params = this.preparedConditions;

            this.$http.get(ADMISSION_ACTS_RESOURCE_ENDPOINT, {params: params})
                .then(response => {
                    this.items = this.prepareItems(response.data.data.data);
                    this.meta = {
                        total_rows: response.data.data.total,
                        current_page: response.data.data.current_page,
                        per_page: response.data.data.per_page,
                    }
                })
                .catch(response => this.$root.responseError(response, 'Ошибка'));
        },
        changeActStatus(item) {
            this.$http.put(
                ADMISSION_ACTS_RESOURCE_ENDPOINT + `/${item.id}/cancel`,
                {
                    canceled: !item.canceled,
                    comment_of_cancel: item.canceled === false ? item.comment_of_cancel : ''
                }
            )
                .then(() => {
                    item.canceled = !item.canceled;
                    this.applyFilters();
                    this.$bvModal.hide(this.cancelActModal.id);
                }).catch(error => {
                if (error.data.errors) {
                    error.data.errors.comment.forEach((error) => {
                        this.$toast.error(error);
                    });
                }
            });
        },
        toForm(item) {
            this.$router.push({name: 'AdmissionActEdit', params: {id: item.id}});
        },
        generationLink(number) {
            return number;
        },
        downloadCsv() {
            if (!this.filters.dateFrom || !this.filters.dateTo) {
                return;
            }
            if (this.csvLoading) {
                return;
            }
            this.csvLoading = true;
            this.$http.get(ADMISSION_ACTS_RESOURCE_ENDPOINT + '/report', {
                params: {
                    without_loading: true,
                    dateFrom: this.filters.dateFrom ? moment(this.filters.dateFrom).format('YYYY-MM-DD') : null,
                    dateTo: this.filters.dateTo ? moment(this.filters.dateTo).format('YYYY-MM-DD') : null,
                    ...(this.filters.storage && {storageId: this.filters.storage.id})
                }
            }).then(response => {
                let dateFrom = moment(this.filters.dateFrom).format('DD.MM.YYYY');
                let dateTo = moment(this.filters.dateTo).format('DD.MM.YYYY');
                let name = 'Журнал по входящему сырью ' + dateFrom + '-' + dateTo;
                CSV.download(response.data, name);
            }).catch(response => this.$root.responseError(response, 'Ошибка!')).finally(() => {
                this.csvLoading = false;
            });
        },

        showCancelModal(act) {
            this.cancelActModal.act = act;
            this.$bvModal.show(this.cancelActModal.id);
        },
    },
    mounted() {
        this.initFilters(this, this.filters);
    }
}
</script>

<style>
#admission-act-list .mx-datepicker {
    width: 100% !important;
}

#admission-act-list .mx-input-wrapper {
    width: 100% !important;
}

#admission-act-list .mx-input {
    width: 100% !important;
    height: 35px !important;
}
</style>

<style scoped>
>>> .nomenclature_preview {
    min-width: 150px;
    font-size: small;
}
</style>
