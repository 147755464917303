<template>
    <select v-model="internalValue" class="form-control" @change="onChange">
        <option :value="null">Выберите статус акта</option>
        <option v-for="option in options" :value="option.id">
            {{ option.name }}
        </option>
    </select>
</template>

<script>
import { ACT_STATUSES_ENDPOINT } from '@utils/endpoints';

export default {
    name: "ActStatusPicker",
    props: {
        value: {},
    },
    data() {
        return {
            internalValue: this.value ? this.value.id : null,
            options: []
        }
    },
    methods: {
        onChange() {
            this.$emit('input', this.options.find(shift => shift.id === this.internalValue))
        },
        load() {
            this.$http.get(ACT_STATUSES_ENDPOINT).then( response => {
                this.options = response.data.data.data;
            }).catch( response => this.$root.responseError(response, 'Ошибка загрузки статусов актов'));
        }
    },
    mounted() {
        this.load();
    },
}
</script>

<style scoped>

</style>
